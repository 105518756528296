import {
    Datagrid, 
    EditButton, 
    FunctionField, 
    List, 
    SimpleList, 
    TextField,
    ReferenceInput,
    SelectInput,
    ReferenceField
} from 'react-admin';
import {useMediaQuery} from '@material-ui/core';
import * as React from "react";
import rowStyle from "./rowStyle";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BigPagination from "../components/BigPagination";
import LcDateField from "../components/LcDateField";
import {CourseAvatar} from "./CourseAvatar";
import {getIterationName} from './utils';
import {getApplicationSpecName} from '../application_specs/utils';
import CloneButtonFetched from '../components/CloneButtonFetched';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
});

export const iterationFilters = [
    <ReferenceInput 
        perPage={100} 
        source="course/program/id"
        label="resources.iterations.fields.course.program.name"
        reference="programs"
    >
        <SelectInput source="id" optionText={((record)=> record.name)} />
    </ReferenceInput>,
    <ReferenceInput 
        perPage={1000} 
        source="application_spec_id" 
        reference="application_specs"
    >
        <SelectInput source="id" optionText={((record)=> getApplicationSpecName(record))} />
    </ReferenceInput>,
];


export const IterationDatagrid = ({permissions, selectedRow, in_courses, in_specs, ...props}) => {
    const classes = useListStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const theme = useTheme();
    const rowS = rowStyle(selectedRow, theme);
    const isAdmin = permissions && permissions.isAdmin();

    return isSmall ? (
            <SimpleList
                rowStyle={rowS}
                primaryText={record => <Typography variant="body2">{getIterationName(record, !in_courses, !in_courses)}</Typography>}
                secondaryText={record => isAdmin ? <>{record.old_id} <strong>{record.applications_nr}</strong> candidaturas</> : null}
                tertiaryText={record =>
                    <>
                        <LcDateField source="start_date" record={record}/>
                        -
                        <LcDateField source="end_date" record={record}/>
                    </>}
                linkType="show"
                leftAvatar={record => <CourseAvatar record={record}/>}
            />
        ) : (
            <Datagrid rowClick="show"
                rowStyle={rowS}
                classes={{
                  headerRow: classes.headerRow,
                  headerCell: classes.headerCell,
                  rowCell: classes.rowCell,
                }}
                {...props}
            >
            <FunctionField
                label={in_courses ? "resources.iterations.fields.iteration_name" : "Nome"} 
                render={record => getIterationName(record, !in_courses, false)}/>
            {!(in_courses || in_specs) && <TextField source="course.program.slug" sortBy="course/program/slug"/>}
            {!in_specs && (isAdmin ?
                <ReferenceField 
                    source="application_spec_id" 
                    label="resources.application_specs.fields.edition"
                    reference="application_specs" 
                    link="show" 
                    emptyText={'-'} 
                    sortable={false}
                >
                    <FunctionField render={record => record?.edition}/>
                </ReferenceField>
                : <TextField source="application_spec.edition" sortable={false}/>
            )}
            <LcDateField source="start_date" />
            <LcDateField source="end_date" />
            {isAdmin &&
                <TextField source="students_nr"/>
            }
            {isAdmin && !in_specs &&
                <TextField source="applications_nr"/>
            }
            {isAdmin &&
                <EditButton/>
            }
            {isAdmin &&
                <CloneButtonFetched />
            }

        </Datagrid>
    );
};

const ResourceList = ({permissions, ...props}) => (
    <List {...props} 
        sort={{ field: 'start_date', order: 'DESC' }} 
        bulkActionButtons={false} 
        pagination={<BigPagination />}
        filters={iterationFilters}
    >
        <IterationDatagrid permissions={permissions} />
    </List>
);


export default ResourceList;