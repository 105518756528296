import { downloadCSV } from "react-admin";
import jsonExport from 'jsonexport/dist';

const FIELDS_TO_EXPORT = [
    'id',
    'session_number',
    'session_start',
    'session_end',
    'is_online',
    'participants_nr',
    'course_iteration.course.name',
    'course_iteration.iteration_name'
];

const getValue = (obj, field) => {
    return field.split('.').reduce((o, key) => o?.[key], obj);
};

export const sessionExporter = async (sessions, _, dataProvider) => {
    const toExport = sessions.map(session => {
        return FIELDS_TO_EXPORT.reduce((acc, field) => {
            acc[field] = getValue(session, field);
            return acc;
        }, {});
    });

    jsonExport(toExport, { headers: FIELDS_TO_EXPORT }, (err, csv) => {
        downloadCSV(csv, 'sessions');
    });
}; 