import {Button, useDataProvider, useNotify, useRefresh, useUnselectAll, useListContext} from 'react-admin';
import {useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import DraftsIcon from "@material-ui/icons/Drafts";

const noSelection = [];

const BulkSendLoginsButton = ({
    selectedIds = noSelection,
}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('applications');
    const [loading, setLoading] = useState(false);
    const [invalidSelection, setInvalidSelection] = useState(true);
    const dataProvider = useDataProvider();
    const {data: listData} = useListContext();

    const selectedApplications = useMemo(
        () => selectedIds.map(id => listData[id]).filter(value => value !== undefined),
        [listData, selectedIds]
    );

    useEffect(() => {
        setInvalidSelection(selectedApplications.some(appl => !(appl.accepted && appl.confirmed)));
    }, [selectedApplications]);

    const submit = async () => {
        setLoading(true);
        dataProvider.create(`course_management/send_student_logins/`, {
            data: {
                raw_request: "POST",
                participants: selectedIds.map(id => {
                    const ids = id.split('/');
                    return {
                        course_iter_id: ids[0],
                        user_id: ids[1]
                    }
                })
            }
        }).then(() => {
            notify("resources.applications.send_student_logins_success");
            unselectAll();
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() => {
            setLoading(false)
            refresh();
        });
    }

    

    return (
        <Button
            label="resources.applications.send_student_logins_button"
            onClick={submit}
            disabled={loading || invalidSelection}
        >
            <DraftsIcon />
        </Button>
    );
};

BulkSendLoginsButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkSendLoginsButton;
