import {Button, useNotify, useRecordContext, useRefresh} from "react-admin";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    MenuItem,
    Button as MUIButton,
    DialogActions,
    makeStyles,
    Grid
} from "@material-ui/core";
import { useDataProvider } from 'react-admin';
import DateRangeIcon from '@material-ui/icons/DateRange';
import * as React from "react";
import MUIDateTimePicker from "../../components/date-time-pickers/MUIDateTimePicker";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const BulkCreateSessionsButton = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();

    const [open, setOpen] = React.useState(false);

    const [nrSessions, setNrSessions] = React.useState(7);
    const [firstSessionStart, setFirstSessionStart] = React.useState(null);
    const [firstSessionEnd, setFirstSessionEnd] = React.useState(null);
    const [sessionType, setSessionsType] = React.useState('in_person');
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
       dataProvider.create(`sessions/bulk-create`, {
            data: {
                raw_request: "POST",
                iteration_id: record.id,
                number_sessions: nrSessions,
                first_session_start: firstSessionStart,
                first_session_end: firstSessionEnd,
                session_type: sessionType
            }
        }).then(() => {
            notify("resources.sessions.bulk_added");
            refresh();
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() => {
            setOpen(false);
        });
    };

    return (
        <div className={classes.root}>
            <Button
                label="resources.iterations.add_weekly_sessions_button"
                variant="contained"
                onClick={handleClickOpen}
            >
                <DateRangeIcon />
            </Button>
            <Dialog  open={open} onClose={handleClose} disableEnforceFocus={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Adicionar Sessões</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta ação adiciona em bulk N sessões para um curso, de 7 em 7 dias, a partir da sessão inicial.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                type="number"
                                fullWidth
                                label="Número de sessões"
                                value={nrSessions}
                                onChange={event => setNrSessions(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                fullWidth
                                label="Tipo de sessões"
                                value={sessionType}
                                onChange={event => setSessionsType(event.target.value)}
                            >
                                <MenuItem value={'in_person'}>Presenciais</MenuItem>
                                <MenuItem value={'online'}>Online</MenuItem>
                                <MenuItem value={'alternating_first_in_person'}>Alternadas - 1ª presencial</MenuItem>
                                <MenuItem value={'alternating_first_online'}>Alternadas - 1ª online</MenuItem>
                            </TextField>
                        </Grid>
                        
                        <Grid item xs={12} md={6}>
                            <MUIDateTimePicker
                                label="Início da primeira sessão"
                                value={firstSessionStart}
                                onChange={setFirstSessionStart}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MUIDateTimePicker
                                label="Fim da primeira sessão"
                                value={firstSessionEnd}
                                onChange={setFirstSessionEnd}
                            />
                        </Grid>
                    </Grid>
                    <br />
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={handleClose} color="primary">
                        Cancelar
                    </MUIButton>
                    <MUIButton
                        disabled={!nrSessions || !firstSessionStart || !firstSessionEnd} 
                        onClick={handleSubmit} 
                        color="primary"
                    >
                        Submeter
                    </MUIButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default BulkCreateSessionsButton;