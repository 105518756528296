import {Datagrid, FunctionField, List, ReferenceField, ReferenceInput, SelectInput, TextField, BooleanField} from 'react-admin';
import Calendar, {getDefaultCalendarFilters} from "../components/Calendar";
import LcDateField from "../components/LcDateField";
import {getIterationName} from "../iterations/utils";
import {sessionExporter} from "./SessionExporter";


export const sessionFilters = [
    <ReferenceInput perPage={1000} source="course_iter_id" reference="iterations" sort={{ field: 'start_date', order: 'DESC' }}>
        <SelectInput
            optionText={(choice) =>
                choice.id // the empty choice is { id: '' }
                    ? getIterationName(choice, true)
                    : ''
            }
        />
    </ReferenceInput>,
];

export const SessionDatagrid = (props) => (
    <Datagrid rowClick="show" {...props}>
        {!props.hide_course &&
        <ReferenceField source="course_iter_id" reference="iterations" link="show">
            <FunctionField render={record => getIterationName(record, true)} />
        </ReferenceField>
        }
        <TextField source="session_number"/>
        <LcDateField showTime={true} source="session_start" />
        <LcDateField showTime={true} source="session_end" />
        <BooleanField source="is_online" />
        <TextField source="participants_nr"/>
    </Datagrid>
);

const converter = (e) => ({
    id: e.id,
    title: `${getIterationName(e.course_iteration, true)} (${e.is_online ? 'O' : 'P'}) #${e.session_number}`,
    start: e.session_start,
    end: e.session_end,
    editable: true,
    //backgroundColor: colorForCategory(event.category),
    //borderColor: colorForCategory(event.category),
});

const ResourceList = props => (
    <List {...props}
        sort={{ field: 'session_start', order: 'DESC' }}
        filters={sessionFilters}
        filterDefaultValues={getDefaultCalendarFilters('session_start')}
        perPage={1000}
        pagination={false}
        exporter={sessionExporter}
    >
        <Calendar
            convertToEvent={converter}
            dateSource="session_start"
            linkType="show"
        />
    </List>
);


export default ResourceList;